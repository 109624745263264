<template>
    <div :class="`wrapper  wrapper--${bu} m-3 p-2`">
        <div class="text-center p-2">
            <h2 class="text-center">Keuringen per dag</h2>
            <span @click="previousDay"><i class="fas fa-arrow-left"></i></span>
            <span class="text-center m-2">{{ day }}-{{ month }}-{{ year }}</span>
            <span @click="nextDay"><i class="fas fa-arrow-right"></i></span>
        </div>
        <Loading v-if="loading" />
        <div v-else class="flex">
            <span v-if="item_examinations.length == 0" class="w100 text-center">Geen Data</span>
            <table v-else :class="`w-100 table__border--${bu}`">
                <thead :class="`table__head--${bu}`">
                    <tr>
                        <th class="table__head--text">Foto</th>
                        <th class="table__head--text">Item</th>
                        <th class="table__head--text">Inkoper</th>
                        <th class="table__head--text">Keurmeester</th>
                        <th class="table__head--text">Keurrapport</th>
                        <th class="table__head--text">Opmerking</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in item_examinations" :key="key">
                        <td class="table__cell--default">
                            <img :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.itemnummer}/HLV`"
                                class="table__picture--max-width" />
                        </td>
                        <td class="table__cell--default">
                            <div>
                                <ImageHover :itemnummer="item.itemnummer" :bu="bu" /><br />
                                {{ item.soort }}<br />
                                {{ item.opbouw }}<br />
                                {{ item.merk }}<br />
                                {{ item.type }}<br />
                                {{ item.regjaar }}<br />
                                <strong>Smaak: </strong>{{ item.selection }}<br />
                                <strong>Vraagprijs:</strong> € {{ item.vraagprijs }}
                            </div>
                        </td>
                        <td class="table__cell--default">
                            {{ item.inkoper }}
                        </td>
                        <td class="table__cell--default">
                            {{ item.keurmeester }}
                        </td>
                        <td class="table__cell--default"> <router-link :to="{ name: 'inspectionrapport', params: { itemno: item.itemnummer, } }">
                                gemiddelde score: {{ item.gemiddeld }}
                            </router-link>
                        </td>
                        <td class="table__cell--default" style="max-width: 500px;">
                            <div style="text-wrap: wrap;overflow-wrap: break-word;">
                            {{ item.opmerking }}
                        </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
  
<script>
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";
import ImageHover from "@/components/ImageHover.vue";
import { subtractTime, addTime } from "@/functions/date.js";

export default {
    props: ["bu"],
    components: { Loading, ImageHover },
    data: () => ({
        loading: true,
        item_examinations: null,
        full_date: null,
        day: null,
        month: null,
        year: null,
    }),
    created() {
        this.full_date = this.moment().subtract(1, "days");
        this.getData(this.bu);
    },
    watch: {
        bu(newbu) {
            this.getData(newbu);
            this.loading = true;
            this.item_examinations = null;
        },
    },
    methods: {
        getData(bu) {
            this.loading = true;
            this.day = this.full_date.date();
            this.month = this.full_date.month() + 1;
            this.year = this.full_date.year();
            request(
                `item-examinations/${bu}/${this.year}/${this.month}/${this.day}`,
                "GET"
            ).then(({ item_examinations }) => {
                this.item_examinations = item_examinations;
                this.loading = false;
            });
        },

        previousDay() {
            this.full_date = subtractTime(1, this.full_date, "days");
            this.getData(this.bu);
        },

        nextDay() {
            this.full_date = addTime(1, this.full_date, "days");
            this.getData(this.bu);
        },
    },
};
</script>
  